import Property from "../api/Property";
import { convertToClass } from "../api/utils";
import moment from "moment";
import Cow from "../api/Cow";

const C = {
	NAME: "Ranch",
};

const initialState = {
	properties: [],
	property: null,
	herd: null,
	error: null,
	random: 0,
	processingConfig: {},
	loadingProperty: false,
};

export const selectors = {
	properties: (state) => convertToClass(state.ranch.properties, Property),
	property: (state) => convertToClass(state.ranch.property, Property),
	createdBy: (state) => state.account.user.data.username,
	processingList: (state) => state.ranch.processingList,
	processingIdx: (state) => state.ranch.processingIdx,
	processingConfig: (state) => state.ranch.processingConfig,
	loadingProperty: (state) => state.ranch.loadingProperty,
};

export const types = {
	CREATE_PROPERTIES: `${C.NAME}/CREATE_PROPERTIES`,
	SET_PROPERTIES: `${C.NAME}/SET_PROPERTIES`,
	GET_PROPERTIES: `${C.NAME}/GET_PROPERTIES`,
	SET_PROPERTY: `${C.NAME}/SET_PROPERTY`,
	GET_PROPERTY: `${C.NAME}/GET_PROPERTY`,
	LOADING_PROPERTY: `${C.NAME}/LOADING_PROPERTY`,

	CREATE_HERD: `${C.NAME}/CREATE_HERD`,
	SET_HERD: `${C.NAME}/SET_HERD`,

	SET_PROCESSING_IDX: `${C.NAME}/SET_PROCESSING_IDX`,
	SET_PROCESSING_LIST: `${C.NAME}/SET_PROCESSING_LIST`,
	SET_PROCESSING_CONFIG: `${C.NAME}/SET_PROCESSING_CONFIG`,

	SET_ERROR: `${C.NAME}/SET_ERROR`,
	SAVE: `${C.NAME}/SAVE`,
};

export const actions = {
	createProperty: (propertyName, herdName, herdType, history) => (dispatch) => {
		Property.create(propertyName, herdName, herdType)
			.then((property) => {
				actions.getProperties()(dispatch);
				/*dispatch({
					type: types.SET_PROPERTY,
					saveProperty: true,
					payload: property,
					afterSaveCB: () => {
						actions.getProperties()(dispatch);
					},
				});*/
			})
			.catch((err) => {
				console.log("In error", err);
				if (err === "401") {
					dispatch({
						type: "Account/RELOGIN",
					});
					return;
				}
				dispatch({
					type: types.SET_ERROR,
					payload: err,
				});
			});
	},
	updateHerd: (herd, history) => (dispatch, state) => {
		const property = selectors.property(state());

		if (herd.data.ID && Number(herd.data.ID) > 0) {
			property.replaceHerd(herd);

			dispatch({
				type: types.SET_PROPERTY,
				saveProperty: true,
				payload: property,
			});
			return;
		}

		herd.data.propertyID = property.data.ID;
		property.addHerd(herd);

		dispatch({
			type: types.SET_PROPERTY,
			saveProperty: true,
			payload: property,
			afterSaveCB: () => {
				actions.getProperty(property.data.ID)(dispatch, state);
			},
		});

		//setTimeout(() => actions.getProperty(property.data.ID)(dispatch, state), 2000);
	},
	reloadProperty: (history) => (dispatch, state) => {
		const property = selectors.property(state());

		if (property) {
			actions.getProperty(property.data.ID)(dispatch, state);
		}

		dispatch({
			type: "NEED_REFRESH",
			payload: false,
		});

		actions.getProperties()(dispatch);
	},
	getProperties: () => (dispatch) => {
		Property.getProperties()
			.then((properties) => {
				dispatch({
					type: types.SET_PROPERTIES,
					payload: properties,
				});
			})
			.catch((err) => {
				if (err === "401") {
					dispatch({
						type: "Account/RELOGIN",
					});
					return;
				}
				dispatch({
					type: types.SET_ERROR,
					payload: err,
				});
			});
	},
	getPropertyForHerd: (herdID) => (dispatch, state) => {
		herdID = Number(herdID);
		const properties = selectors.properties(state());
		const property = selectors.property(state());
		const herd = properties
			.map((p) => {
				if (!p.data.herds) {
					return false;
				}
				return p.data.herds.find((h) => Number(h.data.ID) === herdID);
			})
			.find((h) => !!h);

		if (!property || Number(property.data.ID) !== Number(herd.data.propertyID)) {
			if (state().storeStatus === "Error") {
				dispatch({
					type: types.SAVE,
					saveProperty: true,
					payload: property,
				});
				setTimeout(() => actions.getPropertyForHerd(herdID)(dispatch, state), 2000);
				return;
			}

			if (state().storeStatus === "Saving") {
				return;
			}

			// Get property
			Property.getProperty(herd.data.propertyID)
				.then((property) => {
					dispatch({
						type: types.SET_PROPERTY,
						saveProperty: true,
						payload: property,
					});
				})
				.catch((err) => {
					if (err === "401") {
						dispatch({
							type: "Account/RELOGIN",
						});
						return;
					}
					dispatch({
						type: types.SET_ERROR,
						payload: err,
					});
				});
		}
	},
	getProperty: (propertyID) => (dispatch, state) => {
		const property = selectors.property(state());
		if (!property || Number(property.data.ID) !== Number(propertyID)) {
			if (state().storeStatus === "Error") {
				dispatch({
					type: types.SAVE,
					saveProperty: true,
					payload: property,
				});
				setTimeout(() => actions.getProperty(propertyID)(dispatch, state), 2000);
				return;
			}

			if (state().storeStatus === "Saving") {
				return;
			}

			dispatch({
				type: types.SET_PROCESSING_LIST,
				payload: { cows: null, reset: true },
			});
		}

		const isLoading = selectors.loadingProperty(state());
		if (isLoading) {
			return;
		}

		dispatch({
			type: types.LOADING_PROPERTY,
			payload: true,
		});

		// Get property
		Property.getProperty(propertyID)
			.then((property) => {
				dispatch({
					type: types.SET_PROPERTY,
					//saveProperty: true,
					resetPropertyWithValue: true,
					payload: property,
				});
			})
			.catch((err) => {
				if (err === "401") {
					dispatch({
						type: "Account/RELOGIN",
					});
					return;
				}
				dispatch({
					type: types.SET_ERROR,
					payload: err,
				});
			})
			.finally(() => {
				dispatch({
					type: types.LOADING_PROPERTY,
					payload: false,
				});
			});
	},
	removeProperty: (property, history) => (dispatch, state) => {
		property.data.inactive = true;

		dispatch({
			type: types.SET_PROPERTY,
			saveProperty: true,
			payload: property,
		});
		history.push(`/`);
	},
	updatePropertyName: (property, name) => (dispatch, state) => {
		property.data.name = name;

		dispatch({
			type: types.SET_PROPERTY,
			saveProperty: true,
			payload: property,
		});
	},
	addCalf: (herdID, calf, mother) => {
		return (dispatch, state) => {
			const createdBy = selectors.createdBy(state());

			mother = convertToClass(mother, Cow);
			mother.addCalf(calf, createdBy);
			mother.data.status = "Pair";

			calf.data.momGuid = mother.data.guid;
			calf.data.momHerdID = mother.data.herdID;
			calf.data.createdBy = createdBy;
			calf.data.herdID = herdID;
			calf.data.status = "Pair";

			actions.addCows(herdID, [calf])(dispatch, state);
			actions.replaceCow(mother)(dispatch, state);
		};
	},
	addCows: (herdID, cows, history) => (dispatch, state) => {
		const property = selectors.property(state());
		const herd = property.getHerd(herdID);
		const createdBy = selectors.createdBy(state());
		const clone = Object.assign(Object.create(Object.getPrototypeOf(herd)), herd);

		cows.forEach((cowObj) => {
			const cow = convertToClass(cowObj, Cow);
			if (!cow.data.statusHistory) {
				cow.data.statusHistory = [];
			}
			cow.data.statusHistory.push({
				date: moment().format("YYYY-MM-DD"),
				status: cow.data.status,
				createdBy: createdBy,
			});
			cow.data.createdRancherID = createdBy;
			cow.data.created = moment().format("YYYY-MM-DD");
			clone.addCow(cow, createdBy);
		});
		dispatch({
			type: types.SET_HERD,
			saveProperty: true,
			payload: clone,
		});
		if (history) {
			history.push(`/herd/${herd.data.ID}`);
		}
	},
	removeBlankFromHerd: (herdID) => (dispatch, state) => {
		const property = selectors.property(state());
		const herd = property.getHerd(herdID);
		const clone = Object.assign(Object.create(Object.getPrototypeOf(herd)), herd);

		clone.getCows().forEach((cowObj) => {
			const cow = convertToClass(cowObj, Cow);
			if (cow.data.tag === "") {
				clone.removeCow(cow);
			}
		});
		dispatch({
			type: types.SET_HERD,
			saveProperty: true,
			payload: clone,
		});
	},
	replaceCows: (cows) => (dispatch, state) => {
		const property = selectors.property(state());
		const herd = property.getHerd(cows[0].data.herdID);
		const createdBy = selectors.createdBy(state());
		const clone = Object.assign(Object.create(Object.getPrototypeOf(herd)), herd);

		cows.forEach((cow) => {
			cow = convertToClass(cow, Cow);
			clone.replaceCow(cow, createdBy, true);
		});

		dispatch({
			type: types.SET_HERD,
			saveProperty: true,
			payload: clone,
		});
	},
	replaceCow: (cow, shouldLogStatus) => (dispatch, state) => {
		const property = selectors.property(state());
		const herd = property.getHerd(cow.data.herdID);
		const createdBy = selectors.createdBy(state());
		const clone = Object.assign(Object.create(Object.getPrototypeOf(herd)), herd);

		clone.replaceCow(cow, createdBy, shouldLogStatus);
		dispatch({
			type: types.SET_HERD,
			saveProperty: true,
			payload: clone,
		});
	},
	changeHerd: (cow, herdID, history) => (dispatch, state) => {
		if (Number(cow.data.herdID) === Number(herdID)) {
			return;
		}

		const property = selectors.property(state());
		const createdBy = selectors.createdBy(state());

		const oldHerd = property.getHerd(cow.data.herdID);
		const oldClone = Object.assign(Object.create(Object.getPrototypeOf(oldHerd)), oldHerd);

		const newHerd = property.getHerd(herdID);
		const newClone = Object.assign(Object.create(Object.getPrototypeOf(newHerd)), newHerd);

		cow.data.herdID = herdID;
		oldClone.deleteCow(cow);
		newClone.addCow(cow, createdBy);

		dispatch({
			type: types.SET_HERD,
			payload: oldClone,
		});
		dispatch({
			type: types.SET_HERD,
			saveProperty: true,
			payload: newClone,
		});
		history.push(`/herd/${herdID}/cow/${cow.data.guid}`);
	},
	changeProcessingIdx: (idx, history) => (dispatch, state) => {
		dispatch({
			type: types.SET_PROCESSING_IDX,
			payload: idx,
		});

		const list = selectors.processingList(state());
		const cow = list[idx];
		history.push(`/herd/${cow.herdID}/cow/${cow.guid}`);
	},
	clearProcessingList: (herdID, history) => (dispatch, state) => {
		dispatch({
			type: types.SET_PROCESSING_LIST,
			payload: { cows: null },
		});

		if (history) {
			history.push(`/herd/${herdID}`);
		}
		return;
	},
	setProcessingList: (cows, history, navToFirst = true) => (dispatch, state) => {
		dispatch({
			type: types.SET_PROCESSING_LIST,
			payload: { cows, reset: navToFirst },
		});

		if (!cows) {
			return;
		}

		if (navToFirst) {
			const cow = cows[0];
			history.push(`/herd/${cow.herdID}/cow/${cow.guid}`);
		}
	},
	setProcessingConfig: (config) => (dispatch, state) => {
		dispatch({
			type: types.SET_PROCESSING_CONFIG,
			payload: { config },
		});
	},
	setFinalStatus: (cow, status, history) => (dispatch, state) => {
		const property = selectors.property(state());
		const herd = property.getHerd(cow.data.herdID);
		const createdBy = selectors.createdBy(state());
		let clone = Object.assign(Object.create(Object.getPrototypeOf(herd)), herd);

		cow.data.status = status;
		cow.data.inactive = true;
		clone.replaceCow(cow, createdBy, true);
		dispatch({
			type: types.SET_HERD,
			saveProperty: true,
			payload: clone,
		});
		history.push(`/herd/${herd.data.ID}`);
	},

	removeCow: (cow, history) => (dispatch, state) => {
		const property = selectors.property(state());
		const herd = property.getHerd(cow.data.herdID);
		let clone = Object.assign(Object.create(Object.getPrototypeOf(herd)), herd);
		clone.removeCow(cow);

		dispatch({
			type: types.SET_HERD,
			saveProperty: true,
			payload: clone,
		});
		history.push(`/herd/${herd.data.ID}`);
	},
	addScore: (cow, score) => (dispatch, state) => {
		const property = selectors.property(state());
		const herd = property.getHerd(cow.data.herdID);
		const createdBy = selectors.createdBy(state());

		cow = convertToClass(cow, Cow);
		cow.addScore(score, createdBy);

		let clone = Object.assign(Object.create(Object.getPrototypeOf(herd)), herd);
		clone.replaceCow(cow);
		dispatch({
			type: types.SET_HERD,
			saveProperty: true,
			payload: clone,
		});
	},
	addWeight: (cow, wtType, weight) => (dispatch, state) => {
		const property = selectors.property(state());
		const herd = property.getHerd(cow.data.herdID);
		const createdBy = selectors.createdBy(state());

		cow = convertToClass(cow, Cow);

		cow.addWeight(wtType, weight, createdBy);

		let clone = Object.assign(Object.create(Object.getPrototypeOf(herd)), herd);
		clone.replaceCow(cow);
		dispatch({
			type: types.SET_HERD,
			saveProperty: true,
			payload: clone,
		});
	},
	removeWeight: (cow, wtType, weight) => (dispatch, state) => {
		const property = selectors.property(state());
		const herd = property.getHerd(cow.data.herdID);
		const createdBy = selectors.createdBy(state());

		cow = convertToClass(cow, Cow);

		cow.removeWeight(wtType, weight, createdBy);

		let clone = Object.assign(Object.create(Object.getPrototypeOf(herd)), herd);
		clone.replaceCow(cow);
		dispatch({
			type: types.SET_HERD,
			saveProperty: true,
			payload: clone,
		});
	},
	addTreatment: (cow, treatmentType, treatment, description) => (dispatch, state) => {
		const property = selectors.property(state());
		const herd = property.getHerd(cow.data.herdID);
		const createdBy = selectors.createdBy(state());

		cow = convertToClass(cow, Cow);

		cow.addTreatment(treatmentType, treatment, description, createdBy);

		let clone = Object.assign(Object.create(Object.getPrototypeOf(herd)), herd);
		clone.replaceCow(cow);
		dispatch({
			type: types.SET_HERD,
			saveProperty: true,
			payload: clone,
		});
	},
	removeTreatment: (cow, treatment, date) => (dispatch, state) => {
		const property = selectors.property(state());
		const herd = property.getHerd(cow.data.herdID);
		const createdBy = selectors.createdBy(state());

		cow = convertToClass(cow, Cow);

		cow.removeTreatment(treatment, date, createdBy);

		let clone = Object.assign(Object.create(Object.getPrototypeOf(herd)), herd);
		clone.replaceCow(cow);
		dispatch({
			type: types.SET_HERD,
			saveProperty: true,
			payload: clone,
		});
	},

	removeHerd: (herd, history) => (dispatch, state) => {
		let clone = Object.assign(Object.create(Object.getPrototypeOf(herd)), herd);
		clone.data.inactive = true;
		dispatch({
			type: types.SET_HERD,
			saveProperty: true,
			payload: clone,
		});
		history.push(`/property/${herd.data.propertyID}/manage`);
	},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case types.SET_PROPERTIES:
			return {
				...state,
				properties: action.payload,
			};
		case types.SET_PROPERTY:
			return {
				...state,
				property: action.payload,
			};
		case types.SET_HERD:
			const herd = action.payload;
			const property = convertToClass(state.property, Property);
			property.replaceHerd(herd);
			return {
				...state,
				property: property,
			};
		case types.SET_ERROR:
			return {
				...state,
				error: action.payload.message,
			};
		case types.SET_PROCESSING_IDX:
			return {
				...state,
				processingIdx: action.payload,
			};
		case types.SET_PROCESSING_CONFIG:
			return {
				...state,
				processingConfig: action.payload,
			};
		case types.SET_PROCESSING_LIST:
			const ret = {
				...state,
				processingList: action.payload.cows,
			};
			if (action.payload.reset) {
				ret.processingIdx = 0;
			}
			return ret;
		case types.LOADING_PROPERTY:
			return {
				...state,
				loadingProperty: action.payload,
			};
		default:
			return state;
	}
};
