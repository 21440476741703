import React from "react";
import { bindActionCreators } from "redux";
import { selectors as ranchSelectors, actions } from "../redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Popover from "../../components/Popover";
import { Button, Intent } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";
import CameraCapture from "./CameraCapture";

function ProcessingNav({
	property,
	processingList,
	processingIdx,
	changeProcessingIdx,
	setProcessingList,
	clearProcessingList,
	removeBlankFromHerd,
	processingConfig,
	weightAdded,
	scoreAdded,
	tag,
}) {
	const history = useHistory();

	let pressTimer;

	if (processingIdx >= processingList.length) {
		processingIdx = 0;
	}

	const config = processingConfig && processingConfig.config ? processingConfig.config : {};
	const onComplete = () => {
		if (!processingList[processingIdx].seen) {
			const newObj = processingList.slice();
			newObj[processingIdx].seen = processingList.filter((p) => p.seen).length + 1;
			newObj[processingIdx].tag = tag;
			setProcessingList(newObj, history, false);
		}
		changeProcessingIdx(processingIdx + 1, history);
	};

	const cow = processingList[processingIdx];
	let isThisCowDone = processingList[processingIdx].seen;
	let isRequiredMet = true;
	let isWeightMet = true;
	let isScoreMet = true;
	if (!isThisCowDone) {
		if (config.requireWeight && !weightAdded) {
			isRequiredMet = false;
			isWeightMet = false;
		}

		if (config.requireScore && !scoreAdded) {
			isRequiredMet = false;
			isScoreMet = false;
		}
	}

	if (tag === "") {
		isRequiredMet = false;
	}

	const stopAndRemoveBlank = () => {
		removeBlankFromHerd(cow.herdID);
		clearProcessingList(cow.herdID, history);
	};

	const completed = processingList.filter((p) => p.seen).length;
	const total = processingList.length;

	const finishError = (
		<div>
			<Popover>
				<Button intent={Intent.DANGER} icon={"cross"} large={true}>
					Finish ({total - completed} left)
				</Button>
				<div style={{ padding: 20 }}>
					<p>
						A working can be saved for later or until a new working is started. You have <b>{total - completed}</b>{" "}
						remaining to process.
					</p>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<Button
							intent={completed === total - 1 ? Intent.SUCCESS : Intent.DANGER}
							large={true}
							icon={completed === total - 1 ? "tick" : "cross"}
							onClick={stopAndRemoveBlank}
						>
							{completed === total - 1 ? "Done" : "Finish & Stop"}
						</Button>
						<Button
							large={true}
							intent={Intent.PRIMARY}
							icon={"tick"}
							onClick={() => history.push(`/herd/${cow.herdID}`)}
						>
							{completed === total - 1 ? "Done" : "Save For Later "}
						</Button>
					</div>
				</div>
			</Popover>
		</div>
	);
	const finishSuccess = (
		<Button
			intent={completed === total - 1 ? Intent.SUCCESS : Intent.DANGER}
			icon={completed === total - 1 ? "tick" : "cross"}
			large={true}
			onClick={() => clearProcessingList(cow.herdID, history)}
		>
			{completed === total - 1 ? "Done" : "Finish & Stop"} ({total - completed} left)
		</Button>
	);

	let finish = !isRequiredMet || (processingIdx === total - 1 && completed !== total - 1) ? finishError : finishSuccess;
	if (processingIdx !== total - 1) {
		finish = null;
	}

	const goToFirst = () => {
		console.log("first");
		changeProcessingIdx(0, history);
	};

	const goBack = () => {
		console.log("back");
		if (processingIdx === 0) {
			return;
		}
		changeProcessingIdx(processingIdx - 1, history);
	};

	const goToLast = () => {
		console.log("Last");
		changeProcessingIdx(processingList.length - 1, history);
	};

	const goNext = () => {
		console.log("next");
		if (processingIdx === processingList.length - 1) {
			return;
		}
		changeProcessingIdx(processingIdx + 1, history);
	};

	window.oncontextmenu = function (event) {
		event.preventDefault();
		event.stopPropagation();
		return false;
	};

	return (
		<div>
			{/*<Swipeable
				onSwipedRight={(eventData) => {
					if (processingList) {
						const prev = processingIdx - 1;
						if (prev >= 0) {
							changeProcessingIdx(prev, history);
						}
						return;
					}
				}}
				onSwipedLeft={(eventData) => {
					if (processingList) {
						const next = processingIdx + 1;
						if (next < processingList.length) {
							changeProcessingIdx(next, history);
						}
						return;
					}
				}}
			>*/}
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					marginTop: -5,
					marginBottom: 10,
				}}
			>
				<div>
					<Button
						disabled={processingIdx === 0}
						icon={"less-than"}
						large={true}
						//onClick={() => changeProcessingIdx(processingIdx - 1, history)}
						onPointerDown={(e) => {
							pressTimer = window.setTimeout(() => {
								pressTimer = null;
								goToFirst();
							}, 1000);
						}}
						onPointerUp={() => {
							if (pressTimer) {
								clearTimeout(pressTimer);
								goBack();
							}
						}}
					></Button>
					{/*<Button
						disabled={processingIdx === 0}
						icon={"less-than-or-equal-to"}
						large={true}
						onClick={() => changeProcessingIdx(0, history)}
					></Button>*/}
				</div>
				<div style={{ textAlign: "center" }}>
					<div>
						Completed {processingList.filter((p) => p.seen).length}
						<br />
						Viewing {processingIdx + 1} of {total}
					</div>
					{/*<div style={{ color: "gray" }}>
						<i>Swipe here</i>
					</div>*/}
				</div>

				{processingIdx < total - 1 && (
					<div>
						{/*<Button
							disabled={processingIdx === total - 1}
							icon={"greater-than-or-equal-to"}
							large={true}
							onClick={() => changeProcessingIdx(total - 1, history)}
						></Button>*/}
						<Button
							disabled={processingIdx === total - 1}
							icon={"greater-than"}
							large={true}
							//onClick={() => changeProcessingIdx(processingIdx + 1, history)}
							onPointerDown={(e) => {
								pressTimer = window.setTimeout(() => {
									pressTimer = null;
									goToLast();
								}, 1000);
							}}
							onPointerUp={() => {
								if (pressTimer) {
									clearTimeout(pressTimer);
									goNext();
								}
							}}
						></Button>

						{isThisCowDone && (
							<Button
								intent={Intent.SUCCESS}
								disabled={!isRequiredMet}
								icon={"tick"}
								large={true}
								onClick={() => onComplete()}
							></Button>
						)}

						{!isThisCowDone && (
							<Popover propToMonitor={tag} defaultIsOpen={false}>
								<Button intent={Intent.SUCCESS} disabled={!isRequiredMet} large={true} icon={"floppy-disk"}></Button>
								<div style={{ padding: 20 }}>
									Please confirm tag number:
									<h1 style={{ width: "100%", fontSize: 50, justifyContent: "center", margin: "20px 0px" }}>{tag}</h1>
									<div className={"right"}>
										<Button intent={Intent.SUCCESS} icon={"floppy-disk"} large={true} onClick={() => onComplete()}>
											Save
										</Button>
									</div>
									<div style={{ position: "relative", top: 0, width: 30 }}>
										<CameraCapture
											actionIcon={<Button intent={Intent.SUCCESS} icon={"camera"} large={true}></Button>}
											setValue={(results) => {
												if (!results || results.length === 0) {
													return;
												}
												for (let i = 0; i < results.length; i++) {
													if (results[i].toLowerCase().trim() === cow.tag.toLowerCase().trim()) {
														onComplete();
														return;
													}
												}
											}}
											position={"auto"}
										/>
									</div>
								</div>
							</Popover>
						)}
					</div>
				)}
				{finish}
			</div>
			<br />
			{/*</Swipeable>*/}
			<div style={{ color: "#db3737" }}>
				{!isWeightMet && <span>Weight is required &nbsp;&nbsp;&nbsp;</span>}
				{!isScoreMet && <span>Score is required&nbsp;&nbsp;&nbsp;</span>}
				{tag === "" && <span>Tag is required&nbsp;&nbsp;&nbsp;</span>}
			</div>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	property: ranchSelectors.property,
	processingList: ranchSelectors.processingList,
	processingIdx: ranchSelectors.processingIdx,
	processingConfig: ranchSelectors.processingConfig,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			changeProcessingIdx: actions.changeProcessingIdx,
			setProcessingList: actions.setProcessingList,
			clearProcessingList: actions.clearProcessingList,
			removeBlankFromHerd: actions.removeBlankFromHerd,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(ProcessingNav);
