import React, { useEffect, useState } from "react";

import Popover from "../components/Popover";
import { Button, FormGroup, HTMLSelect, Icon, InputGroup, Intent } from "@blueprintjs/core";
import { bindActionCreators } from "redux";
import { actions, selectors as ranchSelectors } from "./redux";
import { selectors as accountSelectors } from "../Account/redux";
import { connect } from "react-redux";
import Page from "../components/Page";
import { createStructuredSelector } from "reselect";
import { NavLink } from "react-router-dom";

import "./index.scss";
import * as Classes from "@blueprintjs/core/lib/cjs/common/classes";
import CollapseDiv from "./components/CollapseDiv";
import Summary from "./components/Summary";

function Company({ getProperties, properties, createProperty, user, storeStatus }) {
	useEffect(() => {
		getProperties();
	}, [getProperties, storeStatus]);

	const [propertyName, setPropertyName] = useState("");
	const [herdName, setHerdName] = useState("");
	const [herdType, setHerdType] = useState("Cow");

	const saveProperty = () => {
		createProperty(propertyName, herdName, herdType);
		setPropertyName("");
		setHerdName("");
	};

	if (!properties) {
		properties = [];
	}

	const summaries = properties.map((p) => {
		return p.getSummary(true);
	});

	let grandTotal = 0;
	const totalSummary = {};
	const totalPropertySummary = {};
	const totalTypeSummary = {};
	summaries.forEach((summary) => {
		Object.keys(summary).forEach((k) => {
			const propertyName = summary[k].propertyName;

			if (!totalSummary[k]) {
				totalSummary[k] = 0;
			}
			if (!totalTypeSummary[summary[k].type]) {
				totalTypeSummary[summary[k].type] = 0;
			}
			if (!totalPropertySummary[propertyName]) {
				totalPropertySummary[propertyName] = 0;
			}

			totalSummary[propertyName + "-" + k] += summary[k].total;
			totalPropertySummary[propertyName] += summary[k].total;
			totalTypeSummary[summary[k].type] += summary[k].total;
			grandTotal += summary[k].total;
		});
	});

	const displayItem = (property, summary) => {
		return (
			<div key={`property-${property.data.ID}`} className={"property-item"}>
				<CollapseDiv>
					<div className={""}>
						<NavLink to={`/property/${property.data.ID}`}>
							<div className={"right"}>
								{property.countCows()} Head <Icon icon={"caret-right"} />
							</div>
							<div>{property.data.name} </div>
						</NavLink>
					</div>
					<div className={"herds"}>
						<br />
						{property.getHerds().map((herd) => (
							<div key={`herd-${herd.data.ID}`} className={"herd-item"}>
								<NavLink to={`/herd/${herd.data.ID}`}>
									<div className={"right"}>
										{herd.data.count} Head &nbsp;
										<Icon icon={"caret-right"} />
									</div>
									<div>{herd.data.name}</div>
								</NavLink>
							</div>
						))}
					</div>
				</CollapseDiv>
			</div>
		);
	};

	const chartData = Object.keys(totalPropertySummary).map((k, i) => {
		return [k, totalPropertySummary[k], totalPropertySummary[k]];
	});

	const chartTypeData = Object.keys(totalTypeSummary).map((k, i) => {
		return [k + "s", totalTypeSummary[k], totalTypeSummary[k]];
	});

	return (
		<Page header={`${user.data.accountName}`} className={"Property"}>
			{properties.length === 0 && <h3>Welcome! Add some properties to get started...</h3>}
			{properties.length > 0 && (
				<div className={"property-item"}>
					<Summary total={grandTotal} dataSets={[[["Name", "Head", { role: "annotation" }], ...chartTypeData]]} />
				</div>
			)}
			{properties &&
				properties.map((property, idx) => {
					return displayItem(property, summaries[idx]);
				})}
			{properties.length > 0 && (
				<div className={"property-item"}>
					<Summary
						title={"Property Summary"}
						total={grandTotal}
						dataSets={[[["Name", "Head", { role: "annotation" }], ...chartData]]}
					/>
				</div>
			)}
			<div style={{ float: "right" }}>
				<Popover hasBackdrop={true} minimal={true} position={"bottom"} propToMonitor={properties}>
					<Button intent={Intent.PRIMARY}>New Property</Button>
					<div style={{ padding: "20px" }}>
						<FormGroup labelFor="propertyName" label={"Property"}>
							<InputGroup
								id="propertyName"
								placeholder="Property"
								value={propertyName}
								onChange={(e) => setPropertyName(e.target.value)}
							/>
						</FormGroup>
						<br />
						<FormGroup labelFor="herdName" label={"Herd"}>
							<InputGroup
								id="herdName"
								placeholder="Herd"
								value={herdName}
								onChange={(e) => setHerdName(e.target.value)}
							/>
						</FormGroup>
						<br />
						<FormGroup labelFor="type" label={"Herd Type"}>
							<HTMLSelect className={".bp3-fill"} value={herdType} onChange={(e) => setHerdType(e.target.value)}>
								<option value={"Cow"}>Cows</option>
								<option value={"Calf"}>Calfs</option>
								<option value={"Bull"}>Bulls</option>
								<option value={"Stocker"}>Stockers</option>
							</HTMLSelect>
						</FormGroup>
						<br />
						<div className={"right"}>
							<Button className={Classes.POPOVER_DISMISS} intent={Intent.PRIMARY} onClick={saveProperty}>
								Save
							</Button>
						</div>
						<div className={"clear"}></div>
						<br />
						<br />
					</div>
				</Popover>
			</div>
		</Page>
	);
}

const mapStateToProps = createStructuredSelector({
	properties: ranchSelectors.properties,
	user: accountSelectors.user,
	storeStatus: (state) => state.storeStatus,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getProperties: actions.getProperties,
			createProperty: actions.createProperty,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Company);
