import React, { useEffect, useState } from "react";

import Popover from "../components/Popover";
import { Button, Checkbox, Icon, Intent, Spinner } from "@blueprintjs/core";
import { bindActionCreators } from "redux";
import { actions, selectors as ranchSelectors } from "./redux";
import { connect } from "react-redux";
import Page from "../components/Page";
import { createStructuredSelector } from "reselect";
import { useHistory } from "react-router-dom";
import "./index.scss";
import Search from "./Search";
import { NavLink } from "react-router-dom";
import C from "../Constants";
import AddCalf from "./components/AddCalf";
import Summary from "./components/Summary";

function Herd({ property, match, getPropertyForHerd, addCalf, replaceCow, processingList, setProcessingList }) {
	const herdID = Number(match.params.herdID);
	const herd = property ? property.getHerd(herdID) : null;
	const history = useHistory();

	const cows = herd ? herd.getCows() : [];
	const [selected, setSelected] = useState(cows.map((c) => c.data.guid));
	const [page, setPage] = useState(0);

	useEffect(() => {
		/*if (processingList && processingList.length > 0) {
			setProcessingList(null, null, false);
		}*/

		if (!property || !herd) {
			getPropertyForHerd(herdID);
			return;
		}
		const cows = herd.getCows();
		if (cows.length === 0) {
			history.push(`/herd/${herd.data.ID}/process/working`);
			return;
		}
	}, [getPropertyForHerd, property, herdID, herd, history, processingList]);

	if (!property || !herd) {
		return (
			<Page>
				<Spinner />
			</Page>
		);
	}

	const toggleAll = (isTrue) => {
		if (isTrue) {
			setSelected(cows.map((c) => c.data.guid));
			return;
		}
		setSelected(selected.filter((s) => !cows.find((c) => s === c.data.guid)));
	};

	const startProcess = () => {
		setProcessingList(
			herd
				.getCows()
				.filter((c) => selected.includes(c.data.guid))
				.map((c) => {
					return { herdID: herdID, guid: c.data.guid, tag: c.data.tag };
				}),
			null,
			false
		);
		history.push(`/herd/${herdID}/process/working`);
	};

	const resumeProcessing = () => {
		const firstCow = processingList[0];
		history.push(`/herd/${firstCow.herdID}/cow/${firstCow.guid}`);
	};

	const processBlank = () => {
		setProcessingList(
			herd
				.getCows()
				.filter((c) => c.data.tag === "")
				.map((c) => {
					return { herdID: herdID, guid: c.data.guid, tag: c.data.tag };
				}),
			history,
			true
		);
		//history.push(`/herd/${herdID}/process/working`);
	};

	const summary = herd.getSummary();
	const totalSummary = {};
	let grandTotal = 0;
	Object.keys(summary).forEach((k) => {
		if (!totalSummary[k]) {
			totalSummary[k] = 0;
		}
		totalSummary[k] += summary[k].total;
		grandTotal += summary[k].total;
	});

	const start = page * 10;
	const filteredCows = cows.slice(start, start + 10);
	return (
		<Page
			header={`${herd.data.name} - ${property.data.name}`}
			className={"Herd"}
			backLink={`/property/${property.data.ID}`}
		>
			<Search cows={herd.getCows()} />
			<br />
			{/*<Swipeable
				style={{ position: "relative", left: swipePct + "%" }}
				delta={50}
				onSwipedRight={(eventData) => {
					if (eventData.deltaX < -50) {
						history.push(`/property/${property.data.ID}`);
					} else {
						setSwipePct(0);
					}
				}}
				onSwipedLeft={(eventData) => {
					if (cows.length == 0) {
						// Nothing
						return;
					}
					if (eventData.deltaX < 50) {
						setSwipePct(0);
						return;
					}
					history.push(`/herd/${cows[0].data.herdID}/cow/${cows[0].data.guid}`);
				}}
				onSwiping={(eventData) => {
					const pct = (-1 * eventData.deltaX) / 5;
					if (pct > swipePct + 5 || pct < swipePct + 5) {
						setSwipePct(pct);
						return;
					}
				}}
			>*/}
			<div className={"property-item"}>
				<Summary
					total={grandTotal}
					dataSets={[
						[
							["Name", "Head", { role: "annotation" }],
							...Object.keys(totalSummary).map((k, i) => {
								return [k, totalSummary[k], totalSummary[k]];
							}),
						],
					]}
				/>
			</div>

			<br />
			<div className={"right"} style={{ position: "relative", top: -5 }}>
				<Button disabled={selected.length === 0} onClick={() => startProcess()}>
					Process
				</Button>
				{cows.find((c) => c.data.tag === "") && (
					<Button disabled={selected.length === 0} onClick={processBlank} intent={Intent.PRIMARY}>
						Process Blank
					</Button>
				)}
				{processingList && processingList.length > 0 && (
					<Button disabled={selected.length === 0} onClick={resumeProcessing} intent={Intent.PRIMARY}>
						Resume Working
					</Button>
				)}
			</div>

			<br />
			<br />
			{filteredCows.map((cow) => displayItem(cow, property, addCalf, replaceCow, selected, setSelected))}
			<br />

			<div className={"right"}>
				<Button icon={"caret-left"} onClick={() => setPage(page - 1)} disabled={page === 0} />
				<Button icon={"caret-right"} onClick={() => setPage(page + 1)} disabled={start + 10 >= cows.length} />
			</div>
			<Checkbox style={{ margin: 0 }} defaultChecked={true} onChange={(e) => toggleAll(e.target.checked)}>
				Select All
			</Checkbox>

			<div className={"clear"} />
			<br />
			<br />
			{/*</Swipeable>*/}
		</Page>
	);
}

function displayItem(cow, property, addCalf, replaceCow, selected, setSelected) {
	return (
		<div key={`cow-${cow.data.guid}`} className={"property-item"} style={{ marginBottom: -1 }}>
			<div className={"title no-border"}>{displayCow(cow, selected, setSelected)}</div>

			{cow.data.type === "Cow" && (
				<div className={"herds"}>
					{cow.data.status === C.STATUS.PREG && (
						<div className={"herd-item fill"}>
							<Popover hasBackdrop={true} minimal={true} propToMonitor={property}>
								<div>
									<div className={"right"}>
										<Icon icon={"add"} iconSize={20} />
									</div>
									<div>
										<i>Add Calf</i>
									</div>
								</div>
								<AddCalf property={property} inputCow={cow} addCalf={addCalf} replaceCow={replaceCow} />
							</Popover>
						</div>
					)}
				</div>
			)}
		</div>
	);
}

function displayCow(cow, selected, setSelected, isCalf = false) {
	let summary = "";
	if (cow.data.marking !== "") {
		summary += ` - ${cow.data.marking}`;
	}
	if (cow.data.age) {
		//summary += ` - ${cow.data.age}yr`;
	}

	const found = selected.find((c) => cow.data.guid === c) !== undefined;
	return (
		<div>
			<div className={"right"}>
				<NavLink to={`/herd/${cow.data.herdID}/cow/${cow.data.guid}`}>
					{isCalf ? cow.herdName : cow.data.status} &nbsp;
					{!isCalf ? <Icon icon={"caret-right"} /> : ""}
				</NavLink>
			</div>

			<div>
				<Checkbox
					style={{ margin: 0, width: "20px", float: "left" }}
					checked={found}
					onChange={(e) =>
						setSelected(found ? selected.filter((c) => cow.data.guid !== c) : [...selected, cow.data.guid])
					}
				></Checkbox>
				<NavLink to={`/herd/${cow.data.herdID}/cow/${cow.data.guid}`}>
					<span>
						<b>
							{cow.tag || cow.data.tag} {isCalf ? "Calf" : cow.data.type}
							{!isCalf && <span>{summary}</span>}
						</b>
					</span>
				</NavLink>
			</div>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	property: ranchSelectors.property,
	processingList: ranchSelectors.processingList,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getPropertyForHerd: actions.getPropertyForHerd,
			replaceCow: actions.replaceCow,
			addCalf: actions.addCalf,
			setProcessingList: actions.setProcessingList,
			removeHerd: actions.removeHerd,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Herd);
