import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import Popover from "../components/Popover";
import { Icon, Tag } from "@blueprintjs/core";
import { NavLink } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { Intent } from "@blueprintjs/core/lib/cjs/common/intent";
import { actions } from "../Ranch/redux";
import { useHistory } from "react-router-dom";

function Page({
	header,
	children,
	className,
	style,
	pageStyle,
	backLink,
	storeStatus,
	reloadProperty,
	resave,
	logout,
	needRefresh,
}) {
	const history = useHistory();

	const localStorageSpace = function () {
		let allStrings = "";
		for (let key in window.localStorage) {
			if (window.localStorage.hasOwnProperty(key)) {
				allStrings += window.localStorage[key];
			}
		}
		return Math.round(allStrings ? 3 + (allStrings.length * 16) / (8 * 1024) : 0) + "/5000kB";
	};

	const formatBytes = (bytes, decimals = 2) => {
		if (bytes === 0) return "0 Bytes";

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
	};

	const [quota, setQuota] = useState(0);
	useEffect(() => {
		// Using an IIFE
		(async function anyNameFunction() {
			if (navigator.storage && navigator.storage.estimate) {
				const quota = await navigator.storage.estimate();
				// quota.usage -> Number of bytes used.
				// quota.quota -> Maximum number of bytes available.
				const percentageUsed = Math.round(quota.usage / quota.quota) * 100;
				const remaining = quota.quota - quota.usage;
				setQuota(`${percentageUsed}% ${formatBytes(remaining, 0)} / ${formatBytes(remaining, 0)}`);
			} else {
				setQuota(localStorageSpace());
			}
		})();
	}, []);

	return (
		<div className={`App ${className}`} style={style}>
			<header className="App-header">
				<h1 className={"App-title"}>
					{backLink && (
						<NavLink to={backLink}>
							<Icon icon={"arrow-left"} />
						</NavLink>
					)}
					{header}
				</h1>
				<div className={"App-menu"}>
					{storeStatus !== null && storeStatus !== "Saved" && (
						<Tag
							className={"app-tag"}
							intent={Intent.WARNING}
							onClick={() => {
								resave();
							}}
						>
							{storeStatus}
						</Tag>
					)}
					<Popover hasBackdrop={true} minimal={true}>
						<span icon="menu" className="bp3-icon bp3-icon-menu" style={{ position: "relative", top: -5 }}>
							<svg data-icon="menu" width="30" height="30" viewBox="0 0 519.000000 480.000000">
								<g transform="translate(0.000000,480.000000) scale(0.100000,-0.100000)" fill="#EAE8E5" stroke="none">
									<path
										d="M518 4570 c-62 -11 -138 -36 -216 -71 l-62 -28 2 -1253 3 -1253 35 0
35 0 5 1220 5 1219 70 28 c95 36 192 52 295 45 325 -19 576 -294 597 -653 18
-310 -172 -577 -472 -661 -44 -12 -103 -17 -225 -17 -91 -1 -171 -5 -177 -9
-22 -14 -16 -65 9 -78 40 -21 238 -24 356 -4 l107 18 80 -27 c143 -49 251
-126 315 -226 19 -29 57 -125 91 -233 33 -100 85 -233 115 -296 48 -98 67
-125 132 -190 126 -125 246 -171 447 -171 216 0 331 47 467 188 182 189 197
203 268 236 146 70 367 72 540 4 95 -37 185 -103 332 -243 79 -75 169 -154
201 -175 302 -203 667 -48 978 416 89 132 105 168 91 198 -8 18 -19 26 -37 26
-21 0 -33 -12 -63 -61 -120 -197 -293 -393 -417 -474 -122 -80 -260 -110 -368
-82 -91 24 -157 71 -317 223 -270 257 -400 318 -680 318 -130 0 -153 -3 -225
-27 -102 -34 -172 -81 -260 -176 -173 -188 -227 -228 -346 -256 -84 -19 -248
-19 -324 0 -218 57 -317 184 -441 566 -31 96 -67 195 -79 220 -57 112 -165
212 -298 274 l-68 31 73 55 c121 90 216 228 263 379 9 31 19 106 22 170 18
381 -182 695 -512 806 -63 21 -103 27 -190 30 -60 2 -131 -1 -157 -6z"
									/>
									<path
										d="M2263 1739 c-41 -15 -30 -58 45 -171 38 -57 119 -180 180 -275 l112
-171 0 -364 0 -363 48 -52 c43 -48 54 -54 126 -73 44 -11 128 -25 187 -32 125
-14 261 -2 402 35 74 19 92 28 136 70 l51 48 0 364 0 364 180 281 c99 154 180
289 180 300 0 24 -41 53 -63 44 -8 -3 -45 -51 -80 -107 -35 -56 -119 -187
-186 -292 l-121 -189 0 -361 0 -362 -23 -21 c-23 -22 -28 -22 -363 -22 l-341
0 -21 23 c-22 23 -22 27 -22 385 l0 362 -187 286 c-102 157 -193 289 -202 293
-9 5 -26 5 -38 0z"
									/>
								</g>
							</svg>
						</span>
						<div>
							<ul className={"nav-links"}>
								<li>
									<NavLink to={"/"}>Home</NavLink>
								</li>
								<li>
									<a onClick={() => reloadProperty(history)}>Reload From Server</a>
								</li>
								<li>
									<a onClick={() => logout(history)}>Logout</a>
								</li>
							</ul>
						</div>
					</Popover>
				</div>
			</header>
			<div className={"page"} id={"page"} style={pageStyle}>
				{needRefresh && (
					<div
						style={{ marginTop: 0, background: "#f7caca", padding: 10, marginBottom: 10, border: "1px solid red" }}
						onClick={() => reloadProperty()}
					>
						Other users made updates. Click to reload.
					</div>
				)}
				{children}
			</div>
			<br />
			<div style={{ padding: "0 10px" }}>
				<div style={{ float: "right" }}>{quota}</div>
				<div>Version 1.0.24</div>
			</div>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	storeStatus: (state) => state.storeStatus,
	needRefresh: (state) => state.needRefresh,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			reloadProperty: actions.reloadProperty,
			resave: () =>
				dispatch({
					type: "RESAVE",
					saveProperty: true,
					payload: "Error",
				}),
			logout: (history) =>
				dispatch({
					type: "LOGOUT",
					history: history,
					wipeStorage: true,
					wipeAccount: true,
					payload: "Error",
				}),
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Page);
