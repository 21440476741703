import Herd from "./Herd";
import { convertToClass } from "./utils";
import C from "../Constants";
import { ParseBool } from "../util";
import Cow from "./Cow";

class Property {
	constructor(data = {}) {
		this.PRIMARY_KEYS = ["ID", "lastUpdated"];
		this.data = {
			ID: 0,

			name: "",
			herds: [],
			...data,
		};

		if (data.herds) {
			this.data.herds = data.herds.map((herd) => convertToClass(herd, Herd));
		}
	}

	static async getProperties(accountID) {
		const response = await fetch(C.API.URL + "?method=get-properties&XDEBUG_SESSION_START=PHPSTORM", {
			method: "GET",
			mode: "cors",
			cache: "no-cache",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
		});
		if (response.status === 401) {
			throw "401";
		}
		const parsed = await response.json();
		return convertToClass(parsed, Property);
	}

	static async getProperty(propertyID) {
		const response = await fetch(
			C.API.URL + "?method=get-property&id=" + propertyID + "&XDEBUG_SESSION_START=PHPSTORM",
			{
				method: "GET",
				mode: "cors",
				cache: "no-cache",
				credentials: "include",
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		if (response.status === 401) {
			throw "401";
		}
		const parsed = await response.json();

		//For the overcomplicated store logic
		localStorage.setItem("lastUpdated", parsed.data.lastUpdated);
		return convertToClass(parsed, Property);
	}

	static async create(propertyName, herdName, herdType) {
		const response = await fetch(C.API.URL + "?method=create-property&XDEBUG_SESSION_START=PHPSTORM", {
			method: "POST",
			mode: "cors",
			cache: "no-cache",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				data: {
					name: propertyName,
					herdName: herdName,
					herdType: herdType,
				},
			}),
		});
		if (response.status === 401) {
			throw "401";
		}
		const parsed = await response.json();
		return convertToClass(parsed, Property);
	}

	getMarkings() {
		const herds = this.getHerds();
		const markings = [];
		herds.forEach((h) => {
			const cows = h.getCows();
			cows.forEach((c) => {
				if (!c.data.marking || c.data.marking.trim() === "") {
					return;
				}
				if (markings.includes(c.data.marking)) {
					return;
				}
				markings.push(c.data.marking);
			});
		});
		return markings;
	}

	getSources() {
		const herds = this.getHerds();
		const sources = [];
		herds.forEach((h) => {
			const cows = h.getCows();
			cows.forEach((c) => {
				if (!c.data.source || c.data.source.trim() === "") {
					return;
				}
				if (sources.includes(c.data.source)) {
					return;
				}
				sources.push(c.data.source);
			});
		});
		return sources;
	}

	getTreatments(type) {
		const herds = this.getHerds();
		const treatmentVals = [];
		herds.forEach((h) => {
			const cows = h.getCows();
			cows.forEach((c) => {
				const treatments = c.data.treatments;
				if (!treatments) {
					return;
				}

				treatments.forEach((t) => {
					if (t.type !== type) {
						return;
					}
					if (t.name.trim() === "" || treatmentVals.includes(t.name)) {
						return;
					}
					treatmentVals.push(t.name);
				});
			});
		});
		return treatmentVals;
	}

	getTreatmentDescriptions(type) {
		const herds = this.getHerds();
		const treatmentVals = [];
		herds.forEach((h) => {
			const cows = h.getCows();
			cows.forEach((c) => {
				const treatments = c.data.treatments;
				if (!treatments) {
					return;
				}

				treatments.forEach((t) => {
					if (t.type !== type) {
						return;
					}
					if (t.description.trim() === "" || treatmentVals.includes(t.description)) {
						return;
					}
					treatmentVals.push(t.description);
				});
			});
		});
		return treatmentVals;
	}

	getHerds() {
		return this.data.herds.filter((h) => h && !ParseBool(h.data.inactive)).slice();
	}

	getHerd(herdID) {
		return this.data.herds.find((h) => Number(h.data.ID) === Number(herdID));
	}

	addHerd(herd) {
		this.data.herds.push(Object.assign({}, herd));
	}

	replaceHerd(herd) {
		const oldIndex = this.data.herds.findIndex((c) => c && Number(c.data.ID) === Number(herd.data.ID));
		if (oldIndex === -1) {
			return;
		}

		const oldHerd = this.data.herds[oldIndex];
		this.data.herds[oldIndex] = convertToClass(herd, Herd);
	}

	countCows() {
		return this.data.herds.reduce((acc, val) => acc + Number(val.data.count), 0);
	}

	getCows() {
		return this.data.herds.reduce((acc, val) => {
			acc.push(...val.getCows());
			return acc;
		}, []);
	}

	getSummary(includeProperty = false) {
		let typeResults = {};
		this.getHerds().forEach((herd) => {
			const herdResult = herd.getSummary(includeProperty ? this.data.name : null);
			typeResults = { ...typeResults, ...herdResult };
		});
		return typeResults;
	}
}
export default Property;
