import React from "react";
import { HTMLSelect } from "@blueprintjs/core";

function ScoreInput({ value, onChange, onRelease }) {
	return (
		<div style={{ marginLeft: 5, width: "calc(100% - 30px)" }}>
			<div>
				<HTMLSelect
					className={".bp3-fill"}
					value={value}
					onChange={(e) => onChange(e.target.value)}
					onPointerDown={onRelease}
				>
					<option key={"score-"} value={""}>
						{""}
					</option>
					<option key={"score-" + 1} value={1}>
						{1}
					</option>
					<option key={"score-" + 2} value={2}>
						{2}
					</option>
					<option key={"score-" + 3} value={3}>
						{3}
					</option>
					<option key={"score-" + 4} value={4}>
						{4}
					</option>
					<option key={"score-" + 5} value={5}>
						{5}
					</option>
					<option key={"score-" + 6} value={6}>
						{6}
					</option>
					<option key={"score-" + 7} value={7}>
						{7}
					</option>
					<option key={"score-" + 8} value={8}>
						{8}
					</option>
					<option key={"score-" + 9} value={9}>
						{9}
					</option>
					<option key={"score-" + 10} value={10}>
						{10}
					</option>
				</HTMLSelect>
			</div>
		</div>
	);
}

export default ScoreInput;
