import React, { useEffect, useState } from "react";
import { Button, InputGroup } from "@blueprintjs/core";
import * as Classes from "@blueprintjs/core/lib/cjs/common/classes";
import { Intent } from "@blueprintjs/core/lib/cjs/common/intent";
import CameraCapture from "./CameraCapture";

function ChangeTag({ value, herd, onChange }) {
	const [tag, setTag] = useState(value);
	const [results, setResults] = useState([]);

	useEffect(() => {
		setTag(value);
		setResults([]);
	}, [value]);

	const cows = herd.getCows();
	let tagsSorted = cows
		.map((c) => c.data.tag.replace(/\D/g, ""))
		.filter((t) => t !== "")
		.sort((a, b) => {
			return a - b;
		});
	let min = Number(tagsSorted[0]);
	for (let i = 0; i < 10000; i++) {
		min = min + 1;
		if (herd.tagExists(String(min))) {
			continue;
		}
		break;
	}

	const alts = [min];
	const exists = tag !== value && herd.tagExists(tag);

	return (
		<div style={{ padding: "20px" }}>
			<b>Change Tag Number</b>
			<br />
			<br />
			<div style={{ display: "flex", position: "relative" }}>
				<div style={{ flexGrow: 1, marginRight: -25 }}>
					<InputGroup autoFocus id="tag" placeholder="Tag" value={tag} onChange={(e) => setTag(e.target.value)} />
				</div>
				<div style={{ marginTop: 7, margin: 7, position: "relative", left: -20 }}>
					<CameraCapture
						setValue={(results) => {
							if (!results || results.length === 0) {
								return;
							}
							const r = Array.from(new Set(results.map((r) => r.toLowerCase().trim())));
							if (r.length === 1) {
								setTag(r[0]);
								return;
							}
							setResults(r);
						}}
						position={"auto"}
					/>
				</div>
			</div>
			<br />
			{exists && <div style={{ color: "#db3737" }}>Tag Exists.</div>}

			{(tag === "" || exists) && (
				<div>
					Select from next available tag
					<div style={{ background: "white", border: "1px solid #ccc", padding: 5 }}>
						{alts.map((f) => (
							<div key={f} style={{ padding: 5 }} onClick={() => setTag(String(f))}>
								{f}
							</div>
						))}
					</div>
					<br />
					<br />
				</div>
			)}
			{results.length > 0 && (
				<div>
					Select from results
					<div style={{ background: "white", border: "1px solid #ccc", padding: 5 }}>
						{results.map((f) => (
							<div key={f} style={{ padding: 5 }} onClick={() => setTag(f)}>
								{f}
							</div>
						))}
					</div>
					<br />
					<br />
				</div>
			)}
			<div className={"right"}>
				<Button
					className={Classes.POPOVER_DISMISS}
					disabled={tag === value || exists}
					intent={Intent.PRIMARY}
					large={true}
					onClick={() => {
						onChange(tag);
					}}
				>
					Save
				</Button>
			</div>
			<br />
			<br />
		</div>
	);
}

export default ChangeTag;
